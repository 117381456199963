// i18next-extract-mark-ns-start visa-mastercard-for-prestashop
//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {List, OrderedList} from 'components/List';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import prestashop_creditcards from 'images/prestashop_creditcards.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {IndexBackground} from 'components/landings/IndexBackground';
import {MainTitleImg} from 'components/combo-pages/MainTitleImg';
import {MainTitleContent} from 'components/combo-pages/MainTitleContent';
import {BlogLink} from 'components/links/Blog';
import {
  BizumForPrestashopLink,
  InternalPageLink,
  PaymentsOrchestrationLink,
  PaypalForPrestashopLink,
  PrestashopPaymentGatewayLink,
  PricingLink,
  RedsysAlternativeLink
} from 'components/links/Pages';
import {SignUpLink} from 'components/links/Other';
import {SetupIntegration} from 'components/combo-pages/SetupIntegration';
import {Bold} from 'components/StickyBanner';

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const VisaMasterCardForPrestashop: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="visa-mastercard-for-prestashop"
        title={t('PrestaShop Credit Card Payments')}
        description={t(
          'Add the PrestaShop credit card integration to your store to boost customer satisfaction and sales. Visa, Mastercard, JCB, Diners, UnionPay. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent sx={{maxWidth: {all: '564px', md: '100%'}}}>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Add PrestaShop credit card payments to your online store</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                You’ve picked your PrestaShop theme and uploaded your products to sell. Now it’s
                time to accept credit card payments for PrestaShop. But to do this, you’ll need an
                online payment gateway that supports credit card payments and integrates with
                PrestaShop.
              </Trans>
              <Trans parent="p">
                Get MONEI to start accepting PrestaShop credit card payments and more payment
                methods from a single platform. Supported card networks include Visa, Mastercard,
                JCB, Diners, and UnionPay.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg
              src={prestashop_creditcards}
              alt="MONEI visa mastercard"
              title="MONEI visa mastercard"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>
                  Accept credit cards and alternative payment methods in your PrestaShop store
                </Trans>
              </SectionHeader>
              <Trans parent="p">
                Accepting credit card payments in your PrestaShop store is essential, but consumers
                also look for many{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                at checkout including{' '}
                <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                , and <PaypalForPrestashopLink>PayPal</PaypalForPrestashopLink> and{' '}
                <BlogLink slug="local-payment-methods">local payment methods</BlogLink>:
              </Trans>
              <List>
                <Trans parent="li">
                  <BizumForPrestashopLink>Bizum</BizumForPrestashopLink> peer-to-peer (P2P) payments
                  (Spain)
                </Trans>
                <li>
                  <Trans>
                    <InternalPageLink slug="payment-methods/multibanco">
                      Multibanco
                    </InternalPageLink>{' '}
                    bank transfer (Portugal)
                  </Trans>
                </li>
                <Trans parent="li">
                  <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>{' '}
                  bank transfer (Belgium)
                </Trans>
              </List>
              <Trans parent="p">
                Reach more people, boost customer satisfaction, and increase sales by accepting more{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> with one{' '}
                <PrestashopPaymentGatewayLink>
                  PrestaShop payment gateway
                </PrestashopPaymentGatewayLink>{' '}
                connection.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Increase conversions with payments orchestration</Trans>
              </SectionHeader>
              <Trans parent="p">
                <Bold>Approve 95% more payments</Bold>, improve the checkout experience, and
                increase e-commerce conversions with{' '}
                <PaymentsOrchestrationLink>payments orchestration</PaymentsOrchestrationLink>.
              </Trans>
              <Trans parent="p">
                Rather than depending on <RedsysAlternativeLink>Redsys</RedsysAlternativeLink> alone
                to process payments, create transaction{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/4412643058833">
                  routing rules
                </AnchorLink>
                . Reduce lost sales due to false{' '}
                <BlogLink slug="online-payment-failure-message">
                  online payment failure messages
                </BlogLink>{' '}
                and give your customers a{' '}
                <BlogLink slug="frictionless-payments">frictionless payment</BlogLink> experience by
                sending transactions to the best performing processor.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Get the lowest PrestaShop payment gateway fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                Save more money to reinvest in growing your store with a flexible PrestaShop payment
                gateway that scales with your business. Use the only payment provider that offers{' '}
                <PricingLink>scaled pricing</PricingLink> — sell more, and watch your transaction
                fees decrease in real-time.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <SetupIntegration>
        <SectionHeader underline>
          <Trans>Enable PrestaShop credit card payments</Trans>
        </SectionHeader>
        <Trans parent="p">
          Follow these steps to quickly and easily start accepting credit card payments for
          PrestaShop:
        </Trans>
        <OrderedList>
          <Trans parent="li">
            <SignUpLink>Sign up for MONEI here</SignUpLink>
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://support.monei.com/hc/articles/360018045518-How-do-I-activate-the-credit-card-payment-method-">
              Configure credit card payments
            </AnchorLink>{' '}
            in your MONEI dashboard
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://docs.monei.com/docs/e-commerce/prestashop/">
              Connect MONEI
            </AnchorLink>{' '}
            to your PrestaShop store
          </Trans>
        </OrderedList>
      </SetupIntegration>
    </>
  );
};

export default VisaMasterCardForPrestashop;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "visa-mastercard-for-prestashop"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
